'use strict';

import Slideout from 'slideout';

export const sidebar = new Slideout({
    'panel': document.getElementById('panel'),
    'menu': document.getElementById('mobile-menu'),
    'padding': 273,
    'tolerance': 70,
    'duration': 250,
    'fx': 'ease',
    'touch': false
});

function sidebarClose(event) {
    event.preventDefault();
    sidebar.close();
}

// Set event handler for sidebar
sidebar
    .on('beforeopen', function() {
        this.panel.classList.add('panel-open');
    })
    .on('open', function() {
        this.panel.addEventListener('click', sidebarClose);
    })
    .on('beforeclose', function() {
        this.panel.classList.remove('panel-open');
        this.panel.removeEventListener('click', sidebarClose);
    });

// Toggle the sidebar on click
document.querySelector('.toggle-button').addEventListener('click', function() {
    sidebar.toggle();
});