'use strict';

window.jQuery = window.$ = require('jquery');
import 'jquery-cropper';
import exif from 'exif-js';

export function init() {

    let $forms = $('.js-profile-picture-form');

    $forms.each(function(index, element) {

        let $form = $(element);
        let $imageInput = $form.find('.js-image-upload');

        let id = $imageInput.attr('id');

        let $imageElement = $('#'+ id +'_preview');
        let $labelElement = $('#'+ id +'_label > span');

        $form.on('submit', e => {
            e.preventDefault();

            let formData = new FormData($form.get(0));

            if ($imageElement.cropper('getCroppedCanvas')) {
                $imageElement.cropper('getCroppedCanvas').toBlob(function(blob) {
                    formData.append($imageInput.get(0).name, blob);

                    $.ajax($form.get(0).action, {
                        method: 'POST',
                        data: formData,
                        processData: false,
                        contentType: false,
                        success: function(data) {
                            window.location = $form.data('js-ajax-success-redirect');
                        },
                        error: function(data) {
                            window.location = $form.data('js-ajax-error-redirect') || $form.data('js-ajax-success-redirect');
                        }
                    });
                });
            } else {
                $.ajax($form.get(0).action, {
                    method: 'POST',
                    data: formData,
                    processData: false,
                    contentType: false,
                    success: function(data) {
                        window.location = $form.data('js-ajax-success-redirect');
                    },
                    error: function(data) {
                        window.location = $form.data('js-ajax-error-redirect') || $form.data('js-ajax-success-redirect');
                    }
                });
            }

        });

        $imageInput.on('change', e => {

            let reader = new FileReader();
            let file = $imageInput.get(0).files[0];

            // make sure we have an image
            if (file.type.match(/image.*/)) {
                reader.onload = function(readerEvent) {
                    let image = new Image();

                    image.onload = function(imageEvent) {
                        let canvas = document.createElement('canvas');
                        let maxSize = 768;
                        let width = image.width;
                        let height = image.height;

                        if (width > height) {
                            if (width > maxSize) {
                                height *= maxSize / width;
                                width = maxSize;
                            }
                        } else {
                            if (height > maxSize) {
                                width *= maxSize / height;
                                height = maxSize;
                            }
                        }

                        canvas.width = width;
                        canvas.height = height;
                        canvas.getContext('2d').drawImage(image, 0, 0, width, height);

                        let dataUrl = canvas.toDataURL('image/jpeg');
                        let resizedImage = dataUrlToBlob(dataUrl);

                        $imageElement.attr('src', dataUrl);
                        $imageElement.cropper('destroy');
                        $imageElement.cropper({
                            autoCrop: true,
                            aspectRatio: 1,
                            viewMode: 1,
                            zoomable: false,
                            rotatable: true,
                            scalable: true,
                            ready: function() {
                                let exifData = exif.getData(file, function() {
                                    let orientation = exif.getTag(this, 'Orientation');
                                    if (orientation !== 'undefined') {
                                        switch (orientation) {
                                            case 2:
                                                $imageElement.cropper('scale', -1, 1);
                                                break;
                                            case 3:
                                                $imageElement.cropper('scale', -1);
                                                break;
                                            case 4:
                                                $imageElement.cropper('scale', 1, -1);
                                                break;
                                            case 5:
                                                $imageElement.cropper('scale', -1, 1);
                                                $imageElement.cropper('rotate', -90);
                                                break;
                                            case 6:
                                                $imageElement.cropper('rotate', 90);
                                                break;
                                            case 7:
                                                $imageElement.cropper('scale', 1, -1);
                                                $imageElement.cropper('rotate', -90);
                                                break;
                                            case 8:
                                                $imageElement.cropper('rotate', -90);
                                                break;
                                        }
                                    }
                                });
                            }
                        })
                    }

                    $labelElement.html(file.name);
                    image.src = readerEvent.target.result;
                }

                reader.readAsDataURL(file);
            } else {
                alert('Bitte nur Bilder auswählen');
            }
        });


    });


}

export function dataUrlToBlob(dataUrl) {
    const BASE64_MARKER = ';base64,';

    if (dataUrl.indexOf(BASE64_MARKER) == -1) {
        let parts = dataUrl.split(',');
        let contentType = parts[0].split(':')[1];
        let raw = parts[1];

        return new Blob([raw], { type: contentType });
    }

    let parts = dataUrl.split(BASE64_MARKER);
    let contentType = parts[0].split(':')[1];
    let raw = window.atob(parts[1]);
    let rawLength = raw.length;

    let uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
}
