if(document.querySelector('.workreport-admin-type') != null) {
	document.querySelector('.workreport-admin-type').addEventListener('change', showExtraWRFields);

	function showExtraWRFields() {
		if($(this).val() == 'wage') {
			$('#' + $(this).data('employmentid') + '_wage').removeClass('-hide');
			$('#' + $(this).data('employmentid') + '_downPayment').addClass('-hide');
		} else {
			$('#' + $(this).data('employmentid') + '_wage').addClass('-hide');
			$('#' + $(this).data('employmentid') + '_downPayment').removeClass('-hide');
		}
	}
}
