if(document.getElementById('calculator_basic_wage') != null) {
	document.getElementById('calculator_basic_wage').addEventListener('change', calculate_hourly_wage);
	document.getElementById('calculator_ahv').addEventListener('change', calculate_hourly_wage);
	document.getElementById('calculator_fak').addEventListener('change', calculate_hourly_wage);
	document.getElementById('calculator_kreditvers').addEventListener('change', calculate_hourly_wage);

	function calculate_hourly_wage() {
		var wage = Math.round($('#calculator_basic_wage').val() * 100) / 100;
		var calculator_ijob_rate = Math.round($('#calculator_ijob_rate').val() * 100) / 100;
		var calculator_ahv_iv_eo_cost = Math.round((wage * $('#calculator_ahv_iv_eo').val() / 100) * 100) / 100;
		var calculator_ahv_cost = Math.round((wage * $('#calculator_ahv').val() / 100) * 100) / 100;
		var calculator_alv_cost = Math.round((wage * $('#calculator_alv').val() / 100) * 100) / 100;
		var calculator_fak_cost = Math.round((wage * $('#calculator_fak').val() / 100) * 100) / 100;
		var calculator_suva_cost = Math.round((wage * $('#calculator_suva').val() / 100) * 100) / 100;
		var calculator_gav_cost = Math.round((wage * $('#calculator_gav').val() / 100) * 100) / 100;
		var calculator_kreditvers_cost = Math.round((wage * $('#calculator_kreditvers').val() / 100) * 100) / 100;
		var calculator_far_cost = Math.round((wage * $('#calculator_far').val() / 100) * 100) / 100;
		var calculator_bvg_cost = Math.round((wage * $('#calculator_bvg').val() / 100) * 100) / 100;

		$('#calculator_ahv_iv_eo_cost').val(calculator_ahv_iv_eo_cost);
		$('#calculator_ahv_cost').val(calculator_ahv_cost);
		$('#calculator_alv_cost').val(calculator_alv_cost);
		$('#calculator_fak_cost').val(calculator_fak_cost);
		$('#calculator_suva_cost').val(calculator_suva_cost);
		$('#calculator_gav_cost').val(calculator_gav_cost);
		$('#calculator_kreditvers_cost').val(calculator_kreditvers_cost);
		$('#calculator_far_cost').val(calculator_far_cost);
		$('#calculator_bvg_cost').val(calculator_bvg_cost);

		var social_contribution = calculator_ahv_iv_eo_cost + calculator_ahv_cost + calculator_alv_cost + calculator_fak_cost + calculator_suva_cost + calculator_gav_cost + calculator_kreditvers_cost + calculator_far_cost + calculator_bvg_cost;
		$('#calculator_social_constribution').val(Math.round(social_contribution * 100) / 100) ;
		var rate = wage + social_contribution;
		$('#calculator_wage_plus_social').val(Math.round(rate * 100) / 100);
		var rate = rate + calculator_ijob_rate;
		$('#calculator_total_cost').val(Math.round(rate * 100) / 100);
	}

	document.getElementById('calculator_ijob_rate_option').addEventListener('change', function() {
		var select_object = document.getElementById('calculator_ijob_rate_option');
		var ijob_rate = select_object.options[select_object.selectedIndex].value;

		$('#calculator_ijob_rate').val(ijob_rate);
		calculate_hourly_wage();
	});

	document.getElementById('calculator_suva_option').addEventListener('change', function() {
		var select_object = document.getElementById('calculator_suva_option');
		var suva_rate = select_object.options[select_object.selectedIndex].value;

		$('#calculator_suva').val(suva_rate);
		calculate_hourly_wage();
	});

	document.getElementById('calculator_far_option').addEventListener('change', function() {
		var select_object = document.getElementById('calculator_far_option');
		var far_rate = select_object.options[select_object.selectedIndex].value;

		$('#calculator_far').val(far_rate);
		calculate_hourly_wage();
	});

	document.getElementById('calculator_bvg_option').addEventListener('change', function() {
		var select_object = document.getElementById('calculator_bvg_option');
		var bvg_rate = select_object.options[select_object.selectedIndex].value;

		$('#calculator_bvg').val(bvg_rate);
		calculate_hourly_wage();
	});

	document.addEventListener('DOMContentLoaded', function() {
		$('.calculator-subnav > .js-selector').on('click', function(e) {
			e.preventDefault();
			let selectedColumn = $(this).data('calculator-column') || 'cost';

			$(this).siblings('.js-selector').removeClass('active');
			$(this).addClass('active');

			$('.calculator-table').attr('data-selected-column', selectedColumn);
		});
	});
}