'use strict';

window.jQuery = window.$ = require('jquery')

import sidebar from './modules/sidebar';
import hourly_wage_calculator from './modules/hourly-wage-calculator';
import employment_form from './modules/employment-form';
import workreport_admin_form from './modules/workreport-admin-form';
import * as accordion from './modules/accordion';
import * as ppf from './modules/profile-picture-form';

const exif = require('exif-js');
const flatpickr = require('flatpickr');
const flatpickr_de = require('flatpickr/dist/l10n/de.js').default.de;

require('jquery-cropper');
require('select2')();

var header = document.getElementById('fixed-header');
var headerIcon = document.querySelector('#fixed-header svg');
var frontPageHeader = document.getElementById('front-page-header');
var cookieAlert = document.getElementById('cookie-alert');
var smallHeader = false;

$(function() {
  ppf.init();
});

function initFlatpickr() {
  var $that = $(this),
      minDate = $that.data('mindate') || null,
      maxDate = $that.data('maxdate') || null,
      enableTime = ($that.data('enabletime')) ? true : false || false,
      minuteIncrement = $that.data('minuteincrement') || 5;

  $that.flatpickr({
    "locale": flatpickr_de,
    "altFormat": enableTime ? "d.m.Y - H:i" : "d.m.Y",
    "altInput": true,
    "minDate": minDate,
    "maxDate": maxDate,
    "enableTime": enableTime,
    "minuteIncrement": minuteIncrement,
    "weekNumbers": true,
    "time_24hr": true
  });
}

function minimizeHeader() {
	if(frontPageHeader != null) {
		if (window.pageYOffset > 347) {
			if(!smallHeader) {
				header.classList.add('front-page-hide');
				window.setTimeout(function() {
					frontPageHeader.classList.add('-hide');
					header.classList.remove('-backgroundimage');
					header.classList.add('-backgroundgradient');
					document.getElementById('icon_logo').classList.remove('-hide');

					header.classList.add('front-page-show');
					header.classList.remove('front-page-hide');
					window.setTimeout(function() {
						header.classList.remove('front-page-show');
						smallHeader = true;
					}, 500);
				}, 500);
			}
		} else {
			if(smallHeader) {
				header.classList.add('front-page-hide-large');
				window.setTimeout(function() {

					document.getElementById('icon_logo').classList.add('-hide');

					frontPageHeader.classList.remove('-hide');
					header.classList.add('-backgroundimage');
					header.classList.remove('-backgroundgradient');
					header.classList.add('front-page-show');

					window.setTimeout(function() {
						header.classList.remove('front-page-hide-large');
						header.classList.remove('front-page-show');
						smallHeader = false;
					}, 500);
				}, 500);
			}
		}
	}
}

document.addEventListener('DOMContentLoaded', function() {
	console.log('logging');

    // Fix header and add background
    window.onscroll = function() {
    	minimizeHeader()
    };

    if(cookieAlert != null) {
    	if(document.cookie.indexOf('hidecookiealert=1') != -1) {
	 		$('#cookie-alert').hide();
	 	}

	 	$('#close-cookie-alert').on('click', function() {
	 		document.cookie = 'hidecookiealert=1;path=/';jQuery('#cookie-alert').slideUp()
	 	});
	}

	$(document).on('change', '.js-file-upload', function(e) {
		let id = $(this).attr('id');
		let labelElement = $('#' + id + '_label > span');

		labelElement.html($(this)[0].files[0].name)
	});

    $('.js-flatpickr').each(initFlatpickr);

    $('.js-select2').select2({
	    "dropdownAutoWidth": true
	});

	$('.js-select2').on('select2:open', function(e) {
	    let withTags = $(this).data('tags') || false;
	    if (!withTags) {
	      $('.select2-search input').prop('focus',false);
	    }
	 });

	$('.js-modal-box-show').on('click', function(e) {
	    let modalBoxId = $(this).data('modal-box-id')
	    let modal = $('#'+modalBoxId);
	    let body = $('body');

	    if (!modal.hasClass('open') && !body.hasClass('modal-present')) {
	      modal.addClass('open');
	      body.addClass('modal-present');
	    }
	});

	$('.js-modal-box-hide').on('click', function(e) {
	    let modal = $(this).parents('.modal-box');
	    let body = $('body');

	    if (modal.hasClass('open') && body.hasClass('modal-present')) {
			modal.removeClass('open');
			body.removeClass('modal-present');
	    }
	 })
});
