if(document.getElementById('employment_permit') != null) {
	document.getElementById('employment_permit').addEventListener('change', setEmploymentPermit);

	function setEmploymentPermit() {
		if($('#employment_permit').val() == 'ch') {
			$('.permit_valid_to_container').addClass('-hide');
			$('.permit_valid_to_container label').removeClass('required');
			$('.permit_valid_to_container input').removeAttr('required');
		} else {
			$('.permit_valid_to_container').removeClass('-hide');
			$('.permit_valid_to_container label').addClass('required');
			$('.permit_valid_to_container input').attr('required', 'required');
		}
	}
}
